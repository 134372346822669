@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@300;400;500;900&display=swap&family=Material+Icons");
@import "./styles/vendor/nucleo/css/nucleo.css";
@import "./styles/argon-dashboard-pro-react.scss";
@import "./styles/variables";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./styles/fonts";
@import "./styles/partials";
//@import './react-multi-carousel/lib/styles.css';

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--purple-dark);
  overflow-x: hidden;

  .column-100 {
    height: 100vh;
  }

  .bg-theme-primary {
    background-color: var(--primary);
  }

  .alert-danger {
    background-color: var(--primary);
  }

  #root {
    .main {
      max-width: 540px;
      margin: 0 auto;
    }

    .main-propostas {
      margin: 0 auto;
    }

    .Container {
      width: calc(100% - 30px);
      max-width: 1700px;
      padding: 0 15px;
      margin: 0 auto;
    }

    .TextMain {
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
    }

    .OrangeText {
      color: var(--primary);
    }
  }

  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .swal2-styled.swal2-confirm {
    background-color: var(--primary);
  }

  .swal2-confirm:focus {
    box-shadow: none !important;
  }

  .InputText {
    border: 2px solid var(--green);
    font-family: "Roboto", sans-serif;
    transition: all 0.3s ease-in-out;
    color: var(--gray-dark);
    border-radius: 8px;
    position: relative;
    text-indent: 20px;
    font-size: 18px;
    padding: 15px 0;
    display: block;
    outline: none;
    width: 100%;

    &.invalid,
    &:focus.invalid {
      border-color: red;
    }

    &:focus {
      border-color: var(--green);
    }
  }

  .react-multi-carousel-dot-list {
    bottom: 10px;
  }

  .react-multi-carousel-dot--active {
    button {
      background: var(--primary);
    }
  }

  .react-multi-carousel-dot {
    button {
      border: none;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.modal {
  &.show {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

@media (max-width: 767px) {
  body {
    #root {
      .Container {
        width: calc(100% - 45px);
      }
    }
  }

  .grecaptcha-badge {
    display: none !important;
  }

  .showXs {
    display: flex !important;
  }

  .hiddenXs {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .showXs {
    display: none !important;
  }

  .hiddenXs {
    display: flex !important;
  }
}
