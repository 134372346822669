.form-analise {

  p {
    color: #4d4c4c;
    font-weight: 500;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }

  b {
    font-weight: 600;
  }

  label {
    color: #4d4c4c;
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px;
  }

  .round {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;

    a {
      color: var(--primary);
      font-weight: 600;
      text-decoration: underline;
    }

    label {
      background-color: #fff;
      border: 2px solid #4d4c4c;
      border-radius: 50%;
      cursor: pointer;
      height: 28px;
      left: 0;
      position: absolute;
      top: 3px;
      width: 28px;
      margin: 0;

      &:after {
        border: 2px solid #4d4c4c;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 6px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
      }
    }

    input[type="checkbox"] {
      visibility: hidden;

      &:checked+label {
        background-color: #fff;
        border: 2px solid #4d4c4c;

        &:after {
          opacity: 1;
        }
      }
    }

    span {
      color: #4d4c4c;
      font-weight: 500;
      margin-left: 25px;
      font-size: 15px;
      line-height: 17px;
    }
  }

  .viewButtons {

    text-align: center;
    margin-top: 20px;

    div {
      margin: 0 auto;
    }

    .AppButton {
      background-color: #33af03;
      color: #fff;
      width: auto;
      padding: 25px 100px;
      height: auto;
      border-radius: 50px;
      margin-bottom: 20px;
      z-index: 9;
    }
  }

  .form-group {
    margin-bottom: 25px;

    label {
      color: #4d4c4c;
      font-weight: 600;
      font-size: 16px;
      margin-left: 20px;
    }

    p {
      text-align: left !important;
    }

    .InputText {
      background-color: #fff;
      border: 2px solid var(--light);
      border-radius: 30px;
      height: 60px;
      padding: 10px 0;

      &.invalid,
      &:focus.invalid {
        border-color: red;
      }

      &:disabled,
      &.disabled {
        cursor: default;
        background-color: #cacaca !important;
      }
    }

    .error {
      color: var(--primary) !important;
      font-size: 13px;
      margin: 5px 20px 0;
      font-weight: 600;
    }
  }
}
@media (max-width: 767px) {
  .form-analise {
    p {
      font-size: 18px !important;
      line-height: 20px;

      &.mt-5 {
        margin-top: 20px !important;
      }

      &.error {
        font-size: 12px !important;
      }
    }

    label {
      font-size: 13px !important;
      margin-left: 23px;
    }

    .round {
      span {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .viewButtons {
      margin-top: 0;

      .AppButton {
        padding: 15px 70px;
        font-size: 16px;
      }
    }
  }
}